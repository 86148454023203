import React from 'react';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend } from 'recharts';



export default function BidOfferEntry({data, products}) {



    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

    const renderLines = () => {

        const lines = products.map((value,index) => (
          <Line
            key={value.productId}
            name={value.shortName}
            type="monotone"
            dataKey={value.productId}
            strokeWidth={2}
            stroke={value.color}
            dot={false}
            connectNulls={true}
          />
        ));
        return lines;
      }

    return ( <div style={{marginLeft: -25, marginBottom: 30, marginTop:20}}>
            {data &&
             <LineChart width={300} height={300} data={data} margin={{bottom: 50}}>

                {renderLines()}

             <CartesianGrid stroke="#ccc" vertical={false} />
             <XAxis dataKey="name" angle={90} dy={30} dx={6} interval={60} tickCount={30}

            tickFormatter={(t) => {
                let tickDate = new Date(t);

                return MONTHS[tickDate.getMonth()] + " " + tickDate.getFullYear().toString().substring(2,4)
               }}

            />
             <YAxis scale="auto" domain={['dataMin', 'dataMax']} tickCount={20} minTickGap={1} />
             <Legend verticalAlign="top" height={80} width={260} align={'left'} iconType={'plainline'} margin={{left: 50 }} wrapperStyle={{marginLeft: 30, left: 34}}/>
           </LineChart>
            }
            </div>
    )
}
