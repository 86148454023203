import React from 'react'
import './BidOfferEntryPriceRow.css'

export default function Bidofferentrypricerow({product}) {

    return (
        <div class="pricerow-row">
                <div class="pricerow-product pricerow ">{product.shortName ? product.shortName : product.product} {product.shortName ? '' : product.info}</div>
                <div class="pricerow-price pricerow">
                    {product.bid}
                    </div>
                <div class="pricerow-price pricerow ">
                    {product.ask}
                    </div>
        </div>
    )
}


