import React, { useState } from 'react'
import './BidOfferEntry.css';
import hero from '../../assets/hero.jpg';
import BidOfferEntryTable from '../BidOfferEntryTable';
import BidOfferChart from '../BidOfferChart'

export default function BidOfferEntry({ stacks, lastUpdate, chartData }) {

    return (
        (stacks && stacks.group1 && stacks.group1.category) ?
            <>
                <div className="boe-outer" style={{height: 'fit-content', width: 1280}}>
                    <img className="boe-hero" src={hero} />
                    <div className="boe-update-side">Last Updated: {lastUpdate} 17:00</div>

                    <div class="table-parent">
                        <div>
                            <BidOfferEntryTable category={stacks.group1.category} products={stacks.group1.products} />
                            <div style={{marginTop: 20}}>
                            <BidOfferEntryTable category={stacks.group6.category} products={stacks.group6.products} />
                            </div>
                        </div>
                        <div>
                            <BidOfferEntryTable category={stacks.group2.category} products={stacks.group2.products} />
                        </div>
                        <div>
                            <BidOfferEntryTable category={stacks.group3.category} products={stacks.group3.products} />
                            <div style={{marginTop: 20}}>
                                <BidOfferEntryTable category={stacks.group4.category} products={stacks.group4.products} />
                            </div>
                            <div style={{marginTop: 20}}>
                            <BidOfferEntryTable category={stacks.group5.category} products={stacks.group5.products} />
                            </div>
                        </div>
                    </div>
                    <div class="table-parent">
                        <div>
                            <BidOfferChart data={chartData.chart1} products={chartData.chart1products} />
                        </div>
                        <div>
                            <BidOfferChart data={chartData.chart2} products={chartData.chart2products} />
                        </div>
                        <div>
                            <BidOfferChart data={chartData.chart3} products={chartData.chart3products} />
                        </div>
                    </div>




                </div>



            </>
            :
            <h3>Loading..</h3>

    )
}
