import React from 'react'
import './BidOfferEntryTable.css'

import BidOfferEntryPriceHeader from '../BidOfferEntryPriceHeader';
import BidOfferEntryPriceRow from '../BidOfferEntryPriceRow';

export default function BidOfferEntryTable({category, products, footer}) {


    return (
        <div class="price-header-table">
            <BidOfferEntryPriceHeader  category={category} />

            {products.sort((a,b) => (a ? a.inputOrder : 0) - (b ? b.inputOrder : 0)).map((product) => {
                return <BidOfferEntryPriceRow key={product.productId} product={product} />
            })
            }
            {category.indexOf('THG-Quote') > -1 &&
            <div style={{backgroundColor:'#fff', fontSize: 14}}>*premium over O, €/GJ</div>
            }
            {category.indexOf('HVO') > -1 &&
            <div style={{backgroundColor:'#fff', fontSize: 12, maxWidth: 280, color: '#aaa'}}>For price levels on forwards, premiums, spreads & escalations please contact our brokers</div>
            }
           

           

        </div>
    )
}

