import Home from './pages/Home/Home'
import Login from './pages/Login/Login';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


const isLoggedIn = () => {
  return localStorage.getItem('token') != null && localStorage.getItem('token').length > 10
}

function App() {
  return (
    <Router>
      { isLoggedIn() ? (
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
      </Routes> )
      :
      (<Routes>
        <Route exact={true} path="/" element={<Login />} />
      </Routes>
       )
      }
    </Router>

  );
}
export default App;