import React, { useEffect, useState } from 'react'
import './Login.css'
import hero from '../../assets/hero.png';
import axios from 'axios';

import BidOfferEntry from '../../components/BidOfferEntry/'

export default function Home(props) {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const doLogin = () => {
        let loginUrl =`${process.env.REACT_APP_API_ROOT}/login`;
        axios.post(loginUrl, {
            username, password
        }).then(res => {
            console.log(res)
            if(res.data.success && res.data.success.token) {
                localStorage.setItem('token', res.data.success.token)
                localStorage.setItem('email', res.data.success.user.email)
                document.location.href='/';
            }
            else {
                alert('Error Logging In')
            }
        })
    }


    return (
        <>
            <header class="page-header">
                <nav>
                    <a href="#0" aria-label="forecastr logo" class="logo">
                        <img src='/logonew.png' style={{ width: 125, marginLeft: -10}} />
                    </a>
                    <button class="toggle-mob-menu" aria-expanded="false" aria-label="open menu">
                        <svg width="20" height="20" aria-hidden="true">
                            <use href="#down"></use>
                        </svg>
                    </button>
                    <ul class="admin-menu">




                    </ul>
                </nav>
            </header>




            <section class="page-content">

                <div>
                    <img src={hero}></img>

                    <div style={{marginLeft: 55, marginTop: 70}}>
                    <h3 style={{marginBottom: 30}}>Please Login to Continue</h3>

                    <input id="username" type="email" value={username} onChange={(e) => setUsername(e.target.value)} required placeholder='Email Address' style={{ height: 30, fontSize: 18, display: 'block',  padding: 3 ,marginTop: 20}} />
                    <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required placeholder='Password' style={{ height: 30, fontSize: 18, display: 'block', marginTop: 10, padding: 3 }} />

                    <button style={{ backgroundColor: '#545454', marginTop: 20, padding: 10, color: 'white', fontSize: 18 }} onClick={doLogin}>Login</button>

                    </div>
                </div>


                <footer class="page-footer">

                </footer>
            </section>
        </>
    )
}
