import React from 'react'
import './BidOfferEntryPriceHeader.css'

export default function BidOfferEnntryPriceHeader({category}) {


    return (
        <div class="priceheaderrow-row">
                <div class="priceheaderrow-category priceheaderrow">{category}</div>
                <div class="priceheaderrow-price priceheaderrow">Bid</div>
                <div class="priceheaderrow-price priceheaderrow">Ask</div>
        </div>
    )
}
